<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="icon: image;ratio: 1.5"></span> Medien
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid uk-grid-small" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-body">
            <finder ref="finder" mode="write"></finder>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Finder from '@/components/finder/Index'
export default {
  components: {
    Finder
  }
}
</script>
